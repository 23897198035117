<template>
    <div class="px-3 py-2">
        <b-sidebar backdrop id="add_notes" style="direction:ltr" aria-labelledby="sidebar-no-header-title" no-header right title="التعليقات" shadow >
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>التعليقات</span>
        </div>
        <div @click="hide" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>اغلاق</span>
      </div>
    </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <v-row>
                <v-col cols="12" md="12" sm="12">
                    <b-button-group  style="width:100%;font-size:0.8rem">
                    <v-text-field
                        placeholder="اضافة ملاحظة"
                        v-model="card.notes"
                        type="text"
                        style="width:100%;"
                        >{{ card.notes }}</v-text-field>
                        <b-button variant="light" @click='createNote()'><i class="fas fa-plus" style="color:green"></i></b-button>
                    </b-button-group>
                </v-col> 
                <v-col cols="12" md="12" sm="12">
                    <v-timeline align-top dense style="direction:ltr;font-size:0.8ren">
                    <v-timeline-item
                        color="pink"
                        small
                        v-for="(item,i) in notes" v-bind:key="i"
                        >
                        <v-row class="pt-l">
                            <v-col cols="12">
                                <strong> {{ item.datetime }} </strong>
                            </v-col>
                            <v-col cols="12">
                                {{ item.description }}
                            </v-col>
                        </v-row>
                        </v-timeline-item>
                    </v-timeline>
                </v-col>
            </v-row>
        </div>
    </template>
    <footer>
        
    </footer>
        </b-sidebar>
      </div>
</template>

<script>
import axios from 'axios'
export default{
    data: () => ({
        card:{
            notes:'',
            cardid:'',
        },
        notes: [
            
        ]
    }),
    created(){
        this.card.cardid = this.$route.params.cardid;
        this.getNotes()
    },
    methods:{
        createNote(){
           const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
           const auth = this.$cookies.get(this.$store.state.COOKIEPhase);
           post.append('type','createCardNote');
           post.append('auth',auth);
           post.append('data[cardid]',this.card.cardid);
           post.append('data[body]',this.card.notes);
           
           axios.post(
               this.$SAMCOTEC.r_path,
               post
           ).then((response) => {
            //
            this.card.notes = '';
            
           }).then(()=>{
               this.getNotes();
           })
        },
        getNotes(){
           // alert(this.card.cardid)
           const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
           const auth = this.$cookies.get(this.$store.state.COOKIEPhase);
           post.append('type','getCardNotes');
           post.append('auth',auth);
           post.append('data[cardid]',this.card.cardid);
           this.notes = [];
           axios.post(
               this.$SAMCOTEC.r_path,
               post
           ).then((response) => {
               
               const res = response.data;
               let results = [];
               if(res.error.number == 200){
                   results = res.results.data.results;
                   
                   for(let i = 0; i < results.length; i++){
                       this.notes.push( {
                           id: results[i].id,
                           datetime: results[i].created_date,
                           description: results[i].body
                       });
                   }
               }
           })
        }
    }
}
</script>