<template>
    <div class="px-3 py-2">
        <b-sidebar backdrop id="add_warning" style="direction:ltr" right title="التنبيهات" shadow >
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <v-row>
                <v-col cols="12" md="12" sm="12">
                    <b-button-group  style="width:100%;font-size:0.8rem">
                    <v-text-field
                        placeholder="إضافة تنبيه"
                        v-model="card.warning"
                        type="text"
                        style="width:100%;"
                        >{{ card.warning }}</v-text-field>
                        <b-button variant="light" @click='createWarning()'><i class="fas fa-plus" style="color:green"></i></b-button>
                    </b-button-group>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                    <v-timeline align-top dense style="direction:ltr;font-size:0.8ren">
                    <v-timeline-item 
                        color="pink"
                        small
                        v-for="(item,i) in wornings" v-bind:key="i"
                        >
                        <v-row class="pt-l">
                            <v-col cols="12">
                                <strong> {{ item.datetime }} </strong>
                            </v-col>
                            <v-col cols="12">
                                {{ item.description }}
                            </v-col>
                        </v-row>
                        </v-timeline-item>
                    </v-timeline>
                </v-col>
            </v-row>
        </div>
        
        </b-sidebar>
      </div>
</template>

<script>
import axios from 'axios'
export default{
    data: () => ({
        card:{
            warning:'',
            cardid:'',
        },
        wornings: [
            
        ]
    }),
    created(){
        this.card.cardid = this.$route.params.cardid;
        this.getWarning()
    },
    methods:{
        createWarning(){
           const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
           const auth = this.$cookies.get(this.$store.state.COOKIEPhase);
           post.append('type','createCardWorning');
           post.append('auth',auth);
           post.append('data[cardid]',this.card.cardid);
           post.append('data[body]',this.card.warning);
           
           axios.post(
               this.$SAMCOTEC.r_path,
               post
           ).then((response) => {
            //
            this.card.warning = '';
            
           }).then(()=>{
               this.getWarning();
           })
        },
        getWarning(){
           // alert(this.card.cardid)
           const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
           const auth = this.$cookies.get(this.$store.state.COOKIEPhase);
           post.append('type','getCardWorning');
           post.append('auth',auth);
           post.append('data[cardid]',this.card.cardid);
           this.wornings = [];
           axios.post(
               this.$SAMCOTEC.r_path,
               post
           ).then((response) => {
               
               const res = response.data;
               let results = [];
               if(res.error.number == 200){
                   results = res.results.data.results;
                   
                   for(let i = 0; i < results.length; i++){
                       this.wornings.push( {
                           id: results[i].id,
                           datetime: results[i].created_date,
                           description: results[i].body
                       });
                   }
               }
           })
        }
    }
}
</script>