<template>
  <v-form ref="formcard">
    <b-sidebar style="direction:ltr" backdrop v-show="!isExist" right id="sidebar-1" aria-labelledby="sidebar-no-header-title" no-header title="إضافة بطاقة" width="95%" shadow>
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:140px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>{{lang.add_maint_request}}</span>
        </div>
        <div @click="hide" id="hideitnow" style="display:none;float:left;margin-left:5px;margin-top:-25px;cursor:pointer;width:100px;text-align:center;" class="btn-danger btn-sm closeme">
          <span>{{lang.close}}</span></div>
        <div @click="hideIt()" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer;width:100px;text-align:center;" class="btn-danger btn-sm closeme">
          <span>{{lang.close}}</span>
      </div>
    </div>
      
      <v-divider style="margin-top:10px"></v-divider>
      <div style="background: #FFF;border:1px solid #ccc" class="ma-3 pa-1">
        <div class="tobbuttom backBlack p-1" :style="`color:#fff !important;direction:` + lang.dir">
          <b-button :style="`border-radius:0;background:transparent;color:#fff;border:none;`">{{lang.customer_type}}</b-button>
          <b-button style="font-weight: bolder;width:150px" id="cInd" :class="`dispFlex btn btn-small redColor borderRad_`+lang.algin" @click="changeCusType(0)" >{{lang.individual_client}} <i id="indiv"  class="fas fa-arrow-down"></i></b-button>
          <b-button style="font-weight: bolder;width:150px" id="cCom" :class="`dispFlex btn btn-small weightColor borderRad_none`" @click="changeCusType(1)" >{{lang.company_clinet}} <i id="comps"  class="fas fa-arrow-down hideme"></i></b-button>
          <b-button style="font-weight: bolder;width:150px" id="cGov" :class="`dispFlex btn btn-small weightColor borderRad_`+lang.lalgin" @click="changeCusType(2)" >{{lang.government_clinet}} <i id="goves" class="fas fa-arrow-down hideme"></i></b-button>
        </div>
        <v-container style="direction:rtl;margin-top:10px;">
            <v-row style="border:1px solid #ccc;direction:rtl; margin-top:-18px;">
              <v-col cols="12" md="2" sm="12">
                <label for="input-live">{{lang.customer_name}}</label>
                  <b-form-input
                    v-model="customer.full_name"
                    aria-describedby="input-live-help input-live-feedback"
                    trim
                    :rules="fieldRules"
                    id="CusName"
                    class="inborder"
                    style="background:#fff700 !important;"
                  ></b-form-input>
              </v-col>
              <v-col cols="12" md="2" sm="12" v-if="isCompany">
                <label for="input-live">{{lang.company_name}}</label>
                  <b-form-input
                    v-model="customer.company_name"
                    aria-describedby="input-live-help input-live-feedback"
                    trim
                    class="inborder"
                    style="background:#fff700 !important;"
                  ></b-form-input>
              </v-col>
              <v-col cols="12" md="2" sm="12">
                <label for="input-live">{{lang.mobile}} 1</label>
                  <b-form-input
                    v-model="customer.mobile"
                    aria-describedby="input-live-help input-live-feedback"
                    trim
                    :state="require.mobile"
                    id="CusMobile"
                    @change="getName()"
                    class="inborder"
                    style="background:#fff700 !important;"
                  ></b-form-input>
              </v-col>
              <v-col cols="12" md="2" sm="12">
                <label for="input-live">{{lang.mobile}} 2</label>
                  <b-form-input
                    v-model="customer.mobile2"
                    aria-describedby="input-live-help input-live-feedback"
                    trim
                    :state="require.mobile"
                    id="CusMobile"
                    @change="getName()"
                    class="inborder"
                  ></b-form-input>
              </v-col>
              <v-col cols="12" md="2" sm="12" v-if="isCompany">
                <label for="input-live">{{lang.company_vatid}}</label>
                <b-form-input
                    v-model="customer.vatid"
                    aria-describedby="input-live-help input-live-feedback"
                    trim
                    class="inborder"
                  ></b-form-input>
              </v-col>
              <v-col cols="12" md="2" sm="12" v-if="isCompany">
                <label for="input-live">{{lang.crt_number}}</label>
                <b-form-input
                    v-model="customer.crt_number" 
                    aria-describedby="input-live-help input-live-feedback"
                    trim
                    class="inborder"
                    style="background:#fff700 !important;"
                  ></b-form-input>
              </v-col>
              <v-col cols="12" md="2" sm="12" v-if="isCompany">
                <label for="input-live">{{lang.building_no}}</label>
                <b-form-input
                    v-model="customer.building_no"
                    aria-describedby="input-live-help input-live-feedback"
                    trim
                    class="inborder"
                    style="background:#d3ffd3 !important"
                  ></b-form-input>
              </v-col>
              <v-col cols="12" md="2" sm="12" v-if="isCompany">
                <label for="input-live">{{lang.street_name}}</label>
                <b-form-input
                    v-model="customer.street_name"
                    aria-describedby="input-live-help input-live-feedback"
                    trim
                    class="inborder"
                    style="background:#d3ffd3 !important"
                  ></b-form-input>
              </v-col>
              <v-col cols="12" md="2" sm="12" v-if="isCompany">
                <label for="input-live">{{lang.branch_number}}</label>
                <b-form-input
                    v-model="customer.branch_number"
                    aria-describedby="input-live-help input-live-feedback"
                    trim
                    class="inborder"
                    style="background:#d3ffd3 !important"
                  ></b-form-input>
              </v-col>
              <v-col cols="12" md="2" sm="12" v-if="isCompany">
                <label for="input-live">{{lang.district}}</label>
                <b-form-input
                    v-model="customer.district"
                    aria-describedby="input-live-help input-live-feedback"
                    trim
                    class="inborder"
                    style="background:#d3ffd3 !important"
                  ></b-form-input>
              </v-col>
              
              <v-col cols="12" md="2" sm="12" v-if="isCompany">
                <label for="input-live">{{lang.zipcode}}</label>
                <b-form-input
                    v-model="customer.zipcode"
                    aria-describedby="input-live-help input-live-feedback"
                    trim
                    class="inborder"
                    style="background:#d3ffd3 !important"
                  ></b-form-input>
              </v-col>
              <v-col cols="12" md="2" sm="12" v-if="isCompany">
                <label for="input-live">{{lang.city}}</label>
                <b-form-input
                    v-model="customer.city"
                    aria-describedby="input-live-help input-live-feedback"
                    trim
                    class="inborder"
                    style="background:#d3ffd3 !important"
                  ></b-form-input>
              </v-col>
              <v-col cols="12" md="3" sm="12" xs="12" v-if="isGov">
                  <label>{{lang.entity_name}}</label>
                  <b-form-input
                      class="inborder"
                      :label="lang.entity_name"
                      v-model="company.entity_name"
                      ></b-form-input>
              </v-col>
              <v-col cols="12" md="3" sm="12" xs="12" v-if="isGov">
                  <label>{{lang.branch_name}}</label>
                  <b-form-input
                      class="inborder"
                      :label="lang.branch_name"
                      v-model="company.branch_name"
                      ></b-form-input>
              </v-col>
              <v-col cols="12" md="1" sm="12" xs="12" v-if="isGov">
                  <label>{{lang.request_number}}</label>
                  <b-form-input
                      class="inborder"
                      :label="lang.request_number"
                      v-model="company.request_number"
                      ></b-form-input>
              </v-col>
              <v-col cols="12" md="1" sm="12" xs="12" v-if="isGov">
                  <label>{{lang.order_number}}</label>
                  <b-form-input
                      class="inborder"
                      :label="lang.order_number"
                      v-model="company.order_number"
                      ></b-form-input>
              </v-col>
              <v-col cols="12" md="1" sm="12" xs="12" v-if="isGov">
                  <label>{{lang.po_number}}</label>
                  <b-form-input
                      class="inborder"
                      :label="lang.po_number"
                      v-model="company.po_number"
                      ></b-form-input>
              </v-col>
              <v-col cols="12" md="3" sm="12" xs="12" v-if="isGov">
                  <label>{{lang.po_date}}</label>
                  <v-dialog
                      ref="dialogd"
                      v-model="modal"
                      :return-value.sync="company.po_date"
                      persistent
                      width="290px"
                  >
                      <template v-slot:activator="{ on, attrs }">
                      <!-- <label>{{lang.po_date}}</label> -->
                      <b-form-input
                          id="input-live"
                          v-model="company.po_date"
                          aria-describedby="input-live-help input-live-feedback"
                          prepend-icon="mdi-calendar"
                          :placeholder="lang.po_date"
                          readonly
                          trim
                          v-bind="attrs"
                          v-on="on"
                          class="input-sm inborder"
                      ></b-form-input>
                      
                      </template>
                      <v-date-picker
                      v-model="date"
                      scrollable
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="modal = false"
                      >
                          Cancel
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogd.save(date)"
                      >
                          OK
                      </v-btn>
                      </v-date-picker>
                  </v-dialog>
              </v-col>
              <!-- <v-col cols="12" md="2" sm="12">
                <label for="input-live">{{lang.comapny_address}}</label>
                <b-form-input
                    v-model="customer.address"
                    aria-describedby="input-live-help input-live-feedback"
                    trim
                    class="inborder"
                  ></b-form-input>
              </v-col> -->

              <v-col cols="12" md="2" sm="12">
                  <label for="input-live">{{lang.plate_number}}</label>
                    <b-form-input
                        v-model="car.plate_number"
                        aria-describedby="input-live-help input-live-feedback"
                        trim
                        id="carPlate"
                        @change="getCar()"
                        class="inborder"
                        style="background:#fff700 !important;"
                      ></b-form-input>
              </v-col>
              <v-col cols="12" md="2" sm="12">
                  <label for="input-live">{{lang.car_model}}</label>
                  <b-form-input
                      v-model="car.model"
                      aria-describedby="input-live-help input-live-feedback"
                      trim
                      class="inborder"
                      style="background:#fff700 !important;"
                    ></b-form-input>
              </v-col>
              <v-col cols="12" md="2" sm="12">
                <label for="input-live">{{lang.car_year}}</label>
                  <b-form-input
                      v-model="car.made_year"
                      aria-describedby="input-live-help input-live-feedback"
                      trim
                      class="inborder"
                      style="background:#fff700 !important;"
                    ></b-form-input>
              </v-col>
              <v-col cols="12" md="2" sm="12">
                  <label for="input-live">{{lang.car_color}}</label>
                  <b-form-input
                      v-model="car.color"
                      aria-describedby="input-live-help input-live-feedback"
                      trim
                      class="inborder"
                    ></b-form-input>
              </v-col>
              <v-col cols="12" md="2" sm="12">
                <label for="input-live">{{lang.vin_number}}</label>
                  <b-form-input
                      v-model="car.vin"
                      aria-describedby="input-live-help input-live-feedback"
                      trim
                      class="inborder"
                    ></b-form-input>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="2"
              >
                <v-dialog
                  ref="dialog1"
                  v-model="modal1"
                  :return-value.sync="date1"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <label for="input-live">{{lang.delivery_date}}</label>
                    <b-form-input
                      id="input-live"
                      v-model="date1"
                      aria-describedby="input-live-help input-live-feedback"
                      prepend-icon="mdi-calendar"
                      :placeholder="lang.delivery_date"
                      readonly
                      trim
                      v-bind="attrs"
                      v-on="on"
                      class="inborder"
                    ></b-form-input>
                  </template>
                  <v-date-picker
                    v-model="date1"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="modal1 = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog1.save(date1)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row style="border:1px solid #ccc;direction:rtl;display:none;">
                <v-col cols="12" md="5" sm="12" class="d-flex align-stretch mb-6 pa-1">
                    <v-row> 
                        <v-col cols="12" md="7" sm="12">
                            <div class="pa-1" style="width:100%;">
                                <h6>{{lang.main_info}}</h6>
                            </div>
                            <v-row class="pa-1" style="width:100%;">
                              <v-col
                                cols="12"
                                sm="12"
                                md="12"
                              >
                                <v-dialog
                                  ref="dialog"
                                  v-model="modal"
                                  :return-value.sync="date"
                                  persistent
                                  width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <label for="input-live">{{lang.received_date}}</label>
                                    <b-form-input
                                      id="input-live"
                                      v-model="date"
                                      aria-describedby="input-live-help input-live-feedback"
                                      prepend-icon="mdi-calendar"
                                      :placeholder="lang.received_date"
                                      readonly
                                      trim
                                      v-bind="attrs"
                                      v-on="on"
                                      class="input-sm inborder"
                                    ></b-form-input>
                                    
                                  </template>
                                  <v-date-picker
                                    v-model="date"
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="modal = false"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.dialog.save(date)"
                                    >
                                      OK
                                    </v-btn>
                                  </v-date-picker>
                                </v-dialog>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                                md="12"
                              >
                                <v-dialog
                                  ref="dialog1"
                                  v-model="modal1"
                                  :return-value.sync="date1"
                                  persistent
                                  width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <label for="input-live">{{lang.delivery_date}}</label>
                                    <b-form-input
                                      id="input-live"
                                      v-model="date1"
                                      aria-describedby="input-live-help input-live-feedback"
                                      prepend-icon="mdi-calendar"
                                      :placeholder="lang.delivery_date"
                                      readonly
                                      trim
                                      v-bind="attrs"
                                      v-on="on"
                                      class="inborder"
                                    ></b-form-input>
                                  </template>
                                  <v-date-picker
                                    v-model="date1"
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="modal1 = false"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.dialog1.save(date1)"
                                    >
                                      OK
                                    </v-btn>
                                  </v-date-picker>
                                </v-dialog>
                              </v-col>
                                <v-progress-linear
                                  v-model="card_status"
                                  height="25"
                                  style="direction:rtl;display:none"
                                >
                                  <strong>{{ Math.ceil(card_status) }}%</strong>
                                </v-progress-linear>
                            </v-row>
                        </v-col>
                        
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" sm="12">
                <CVAdditionalRowNew ref="mywork" :getData="getClicked" :lang="lang" />
                <v-row>
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th class="text-center backBlack" colspan="2">{{lang.received_status}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,index) in scrachNoties" :key="index">
                          <td class="text-center">{{ item }}</td>
                          <td class="text-center" style="width:50px;">
                            <v-btn style="background:red;color:#FFF;width:50px;padding:1px !important" @click="remScr(index)">{{lang.delete}}</v-btn>
                          </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <label for="input-live">{{lang.status}}</label>
                            <b-form-input
                              id="input-live"
                              v-model="scrachnotes"
                              aria-describedby="input-live-help input-live-feedback"
                              :placeholder="lang.status"
                              trim
                              class="inborder"
                            >{{scrachnotes}}</b-form-input>
                            <br>
                        </td>
                        <td class="text-center" style="width:50px;">
                          <v-btn style="background:green;color:#FFF;width:50px;padding:1px !important" @click="addScr()">{{lang.add}}</v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-row>
              </v-col>
              <v-col cols="12" md="8" sm="12">
                  <div style="margin:5px;" class="rowTitle">
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th class="text-center backBlack">{{lang.work_description}}</th>
                          <th class="text-center backBlack">{{lang.worker_name}}</th>
                          <th class="text-center backBlack">{{lang.price}}</th>
                          <th class="text-center backBlack">{{lang.action}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style="width:50%">
                            <v-combobox
                              v-model="wrkDes.work"
                              :items="carworkdes"
                              small-chips
                              style="border:0px !important;"
                            ></v-combobox>
                          </td>
                          <td>
                              <b-input-group>
                              <b-form-select class="selborder" style="width:200px !important" v-model="wrkDes.empl" :options="workers"></b-form-select>
                              <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                <i class="fas fa-arrow-down"></i>
                              </b-input-group-append>
                            </b-input-group>
                          </td>
                          <td>
                            <b-form-input
                                v-model="wrkDes.price"
                                
                              aria-describedby="input-live-help input-live-feedback"
                              :placeholder="lang.price"
                              trim
                              class="inborder"
                              style="background:#fff700 !important;"
                            >{{wrkDes.price}}</b-form-input>
                          </td>
                          <td>
                              <v-btn @click="addWroker()" style="background:green;color:#FFF">{{lang.add}}</v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <br>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th class="text-center backBlack" style="width:50%">{{lang.work_description}}</th>
                          <th class="text-center backBlack">{{lang.worker}}</th>
                          <th class="text-center backBlack">{{lang.price}}</th>
                          <th class="text-center backBlack"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item,index) in DesWrk" :key="index">
                            <td class="text-center" style="white-space:normal !important;">{{item.work}}</td>
                            <td class="text-center">{{item.name}}</td>
                            <td class="text-center" style="width:95px">{{item.price}}</td>
                            <td class="text-center" style="width:95px;padding:5px !important;">
                                <v-btn @click="delWorks(index)" style="background:red;color:#FFF;width:75pxl">حذف</v-btn>
                            </td>
                        </tr>
                        <tr style="background:lightblue">
                            <td class="center backEmpty"></td>
                            <td class="center backBlack">{{lang.total}}</td>
                            <td class="center backBlack">{{servicePrice}}</td>
                            <td class="center backEmpty"></td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <br>
                    
                  </div>   
                  <v-row>
                    <v-col cols="12" md="4" sm="12">
                      <div><label for="input-live">{{lang.vat_type}}</label></div>
                      <b-input-group>
                          <b-form-select class="selborder" v-model="vattype" :options="vattypes" @change="changePtype()">
                          </b-form-select>
                          <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                          </b-input-group-append>
                      </b-input-group>
                        
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                      <label for="input-live">{{lang.advanced_payment}}</label>
                        <b-form-input
                          v-model="payment.paid"
                          style="background:#d9e6eb !important;"
                          aria-describedby="input-live-help input-live-feedback"
                          trim
                          :rules="fieldRules"
                          class="inborder"
                          @change="checkMe()"
                        >{{payment.paid}}</b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                      <div><label for="input-live">{{lang.payment_method}}</label></div>
                      <b-input-group>
                        <b-form-select class="selborder" v-model="paytype" :options="paytypes">
                        </b-form-select>
                        <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                          <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                      </b-input-group>
                    </v-col>
                    
                    <v-col cols="12">
                    
                    </v-col>
                </v-row>
                  <div style="margin:5px;" class="rowTitle">
                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1 backBlack text-center" style="text-align:center" role="tab" @click="invsummary = !!invsummary">
                         {{lang.add_attachement}}</b-card-header>
                      <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                          <b-card-text>
                            <b-button-group  style="width:100%;font-size:0.8rem">
                              <v-row>
                                <v-col cols="12" md="12" sm="12">
                                  <b-button-group  style="width:100%;font-size:0.8rem">
                                      <input
                                          type="file"
                                          multiple
                                          label="lang.choose_file"
                                          style="width:98%;border:1px solid #ccc;padding:5px;color:red"
                                          ref="attached"
                                          class="inborder"
                                          @change="selectFiles()"
                                        >
                                  </b-button-group>
                                </v-col>
                                <v-col cols="12" md="12" sm="12" style="direction:rtl">
                                    <span v-for="(attach,index) in attached" :key="index">
                                        <div class="pa-1 m-2" style="direction:rtl" >
                                            {{ attach.name }}
                                        <i @click.prevent="attached.splice(index,1)" class="fas fa-trash-alt m-2" style="float:left;"></i></div>
                                    </span>
                                </v-col>
                              </v-row>
                            </b-button-group>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                </div>
              </v-col>
            </v-row>
            <!-- <div>
              <canvas ref="canRef" id="demo" style="width:100px;height: 100px;border:1px solid;"></canvas>
            </div> -->

        </v-container>
      </div>
      </template>

      <template #footer="" class="shadow">
        <div class="d-flex text-light align-items-center px-3 py-2">
          <b-button type="button" @click="doAddCard()" id="mysubmit" variant="success" class="ma-2 py-3 btn-sm" style="width:170px;font-size:1.3rem !important;">{{lang.save_n_print}}</b-button>
        </div>

      </template>
    </b-sidebar>
    </v-form>
</template> 


<script>
import CVAdditionalRowNew from '../components/CVAdditionalRowNew.vue'
// import addCustomer from '../components/addCustomer.vue'
// import addCar from '../components/addCar.vue'
import {SnotifyPosition} from 'vue-snotify';
import axios from 'axios'
export default {
  components:{
      CVAdditionalRowNew
  },
  data: () => ({
    isCompany: false,
    isGov: false,
    vattype: 2,
    require:{
      mobile: false,
    },
    scrachNoties:[],
    wrkDes:{
        work: '',
        empl: '',
        price: '',
    },
    DesWrk:[
    ],
    paytype: 1,
    paycash: 0,
    payspan: 0,
    card_status: 10,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    date1: '',
    modal: false,
    modal1: false,
    isExist: false,
    message_notify: '',
    carworkdes: [],
    showmsg: true,
    customer:{
      id: 0,
      mobile: '',
      mobile2: '',
      full_name: '',
      company_name: '',
      crt_number: '',
      vatid: '',
      address: '',
      building_no: '',
      street_name: '',
      branch_number: '',
      district: '',
      zipcode: '',
      city: '',
      cctype: 1,
    },
    car:{
      plate_number: '',
      color: '',
      model: '',
      made_year: '',
      workdes: '',
      vin: '',
    },
    payment:{
      ftotal:'',
      paid:''
    },
    clicked: [],
    workerssel:{
    },
    workers:[
      
    ],
    deltime: true,
    carinfo: true,
    attached:[],
    scrachnotes: '',
    company: {
      entity_name: '',
      branch_name: '',
      entity_branch_number: '',
      request_number: '',
      po_number: '',
      po_date: '',
      order_number: '',
    },
    servicePrice: 0,
    paytypes: [],
  }),
  created() {
    this.getWorkers();
    this.getWorkerds();
    this.getPyamentType();
  },
  computed:{
    lang: {
        get: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        set: function(newValue){
            return newValue
        }
    },
    fieldRules: function() {
      return [
        v => !!v || this.lang.required_field,
      ]
    },
    vattypes: function() {
      return [
        {text: this.lang.prices_include_vat, value: 2},
        {text: this.lang.prices_exclusive_vat, value: 1},
        {text: this.lang.prices_without_vat, value: 3},
      ]
    }
  },
  methods:{
    
    drawItem(index, x, y) {
      // console.log('args:', index, x, y);
      this.canvas = this.$refs.canRef;
      // console.log("canvas:", this.canvas);
      this.ctx = this.canvas.getContext('2d');
      this.img = new Image();
      this.img.src = this.items[index].src;
      // console.log('this.img.src:', this.img.src);
      this.img.onload = function () {
        // console.log('this.ctx:', this.ctx);
        this.ctx.drawImage(this.img, x, y);
      }
    },
    changeCusType(id){
      if(id == 0){
        this.isCompany = false;
        this.isGov = false;
        this.customer.customer_type = 1;
        document.getElementById('indiv').classList.remove('hideme');
        document.getElementById('comps').classList.add('hideme');
        document.getElementById('goves').classList.add('hideme');

        document.getElementById('cInd').classList.remove('redColor');
        document.getElementById('cCom').classList.remove('redColor');
        document.getElementById('cGov').classList.remove('redColor');

        document.getElementById('cInd').classList.add('redColor');
        document.getElementById('cCom').classList.add('weightColor');
        document.getElementById('cGov').classList.add('weightColor');

      }else if(id == 1){
        this.customer.customer_type = 2;
        this.isCompany = true;
        this.isGov = false;
        document.getElementById('comps').classList.remove('hideme');
        document.getElementById('indiv').classList.add('hideme');
        document.getElementById('goves').classList.add('hideme');

        document.getElementById('cInd').classList.remove('redColor');
        document.getElementById('cCom').classList.remove('redColor');
        document.getElementById('cGov').classList.remove('redColor');

        document.getElementById('cInd').classList.add('weightColor');
        document.getElementById('cCom').classList.add('redColor');
        document.getElementById('cGov').classList.add('weightColor');

      }
      else if(id == 2){
        this.isCompany = false;
        this.isGov = true;
        this.customer.customer_type = 3;
        document.getElementById('goves').classList.remove('hideme');
        document.getElementById('comps').classList.add('hideme');
        document.getElementById('indiv').classList.add('hideme');

        document.getElementById('cInd').classList.remove('redColor');
        document.getElementById('cCom').classList.remove('redColor');
        document.getElementById('cGov').classList.remove('redColor');

        document.getElementById('cInd').classList.add('weightColor');
        document.getElementById('cCom').classList.add('weightColor');
        document.getElementById('cGov').classList.add('redColor');

      }
    },
    checkMe(){
      if(this.payment.paid > this.servicePrice){
        this.payment.paid = this.servicePrice;
      }
    },
    resetALl(){
      
      this.isCompany = false
      this.isGov = false
      this.vattype = 2
      this.require ={
        mobile: false,
      }
      this.scrachNoties =[]
      this.wrkDes = {
          work: '',
          price: '',
      }
      this.DesWrk = [
      ]
      this.paytype = 1
      this.paycash = 0
      this.payspan = 0
      this.card_status = 10
      this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.date1 = ''
      this.modal = false
      this.modal1 = false
      this.isExist = false
      this.message_notify = ''
      this.showmsg = true
      this.customer ={
        id: 0,
        mobile: '',
        mobile2: '',
        full_name: '',
        company_name: '',
        crt_number: '',
        vatid: '',
        address: '',
        building_no: '',
        street_name: '',
        branch_number: '',
        district: '',
        zipcode: '',
        city: '',
        cctype: 1,
      }
      this.car ={
        plate_number: '',
        color: '',
        model: '',
        made_year: '',
        workdes: '',
        vin: '',
      }
      this.payment ={
        ftotal:'',
        paid:''
      }
      this.clicked = [],
      this.workerssel ={
      }
      this.workers =[]
      this.deltime = true
      this.carinfo = true
      this.attached =[]
      this.scrachnotes = ''
      this.company = {
        entity_name: '',
        branch_name: '',
        entity_branch_number: '',
        request_number: '',
        po_number: '',
        po_date: '',
        order_number: '',
      }
      this.servicePrice = 0
      this.paytypes = []
      this.$refs.mywork.resetTableAll();
      this.getWorkers();
      this.getWorkerds();
      this.getPyamentType();
    },
    hideIt(){
      this.resetALl();
      document.getElementById('hideitnow').click();
    },
    getwrokerName(value){
        const workers = this.workers;
        for(let i = 0; i < workers.length;i++){
            if(workers[i].value == value){
                return workers[i].text;
            }
        }
    },
    collectServicePrice(){
        const workers = this.DesWrk;
        let ix = 0;
        for(let i = 0; i < workers.length;i++){
            ix = +ix + +workers[i].price
        }
        this.servicePrice = this.$RoundNum(ix);
    },
    delWorks(index){
        this.DesWrk.splice(index,1);
        this.collectServicePrice();
    },
    addWroker(){
        const workdek = this.wrkDes;
        let vald = true;
        let message = '';
        if(workdek.empl == ''){
            message = this.lang.please_choose_worker;
            vald = false;
        }
        if(workdek.work == '' && vald == true){
            message = this.lang.work_description_required;
            vald = false;
        }
        if(workdek.price == '' && vald == true){
            message = this.lang.job_price_required;
            vald = false;
        }

      if(!vald){
        this.$snotify.error(message, 'تنبيه', {
            timeout: 1000000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: SnotifyPosition.centerCenter,
            buttons: [
                {
                    text: this.lang.close, 
                    action: (toast) => {
                        vald = true;
                        message = '';
                        this.$snotify.remove(toast.id); 
                    } 
                },
            ]
        });
        return false;
      }

        this.DesWrk.push({
            empl: this.wrkDes.empl,
            work: this.wrkDes.work,
            price: this.wrkDes.price,
            name: this.getwrokerName(this.wrkDes.empl),
        });
        
        this.collectServicePrice();
        this.wrkDes.empl = '';
        // this.wrkDes.work = '';
        this.wrkDes.price = '';
    },
    addScr(){
      this.scrachNoties.push(this.scrachnotes);
      this.scrachnotes = '';
    },
    remScr(index){
      this.scrachNoties.splice(index,1);
    },
    changePtype(){
      if(this.paytype == 1){
        this.paycash = this.payment.paid;
        this.payspan = 0
      }else if(this.paytype == 2){
        this.paycash = 0
        this.payspan = this.payment.paid;
      }
    },
    getClicked(clicked){
      this.clicked = clicked
    },
    getWorkerds(){
      this.carworkdes = [];
      const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
      post.append('type','getWorkerds');
      post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append('data[mobile]',this.customer.mobile);
      axios.post(
        this.$store.state.SAMCOTEC.r_path, post
      ).then(
        (response) => {
          const res = response.data;
          if(res.error.number == 200){
            this.carworkdes = res.results.data;
          }
        }
      )
    },
    selectFiles(){
        const files = this.$refs.attached.files;
        // console.log("attached",files);
        this.attached = [...this.attached, ...files];
        // console.log("attached",this.attached);
    },
    getWorkers(){
      this.workers = [];
      const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
      post.append('type','getWorkers');
      post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append('data[mobile]',this.customer.mobile);
      axios.post(
        this.$store.state.SAMCOTEC.r_path, post
      ).then(
        (response) => {
          const res = response.data;
          // // console.log("workerresults",res);
          if(res.error.number == 200){
            this.workers = res.results.data;
            this.wrkDes.empl = res.results.data[0].value
          }
        }
      )
    },
    getName(){
      const customer_type = this.customer.customer_type;
      if(this.customer.mobile == '' && this.customer.mobile2 == ''){
        this.require.mobile = false;
      }
      const mobile = this.customer.mobile != '' ? this.customer.mobile : this.customer.mobile2
      const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear')); 
      post.append('type','customerInfo');
      post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append('data[mobile]',mobile);
      axios.post(
        this.$store.state.SAMCOTEC.r_path, post
      ).then(
        (response) => {
          const res = response.data;
          
          if(res.error.number == 200){
            this.customer = res.results.data;
            this.customer.vatid = res.results.data['company_vatid'];
            this.customer.customer_type = customer_type;
            this.company.entity_name = res.results.data['entity_name']
            this.company.branch_name = res.results.data['branch_name']
          }
        }
      )
    },
    getCar(){
      const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
      post.append('type','carInfo');
      post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append('data[plate_number]',this.car.plate_number);
      axios.post(
        this.$store.state.SAMCOTEC.r_path, post
      ).then(
        (response) => {
          const res = response.data;
          if(res.error.number == 200){
            this.car = res.results.data;
          }
        }
      )
    },
    getPyamentType(){
        const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
        post.append('type','getPayTypes');
        post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
        post.append('data[id]',"all");
        axios.post(
            this.$store.state.SAMCOTEC.r_path, post
        ).then((response) => {
            const res = response.data;
            let t = [];
            for(let i=0;i<res.results.data.length;i++){
                t.push({
                    text: this.lang.langname == 'ar' ? res.results.data[i].type_name : res.results.data[i].type_name_en,
                    value: res.results.data[i].id
                })
            }
            this.paytypes = t;
            this.paytype = res.results.data[0].id;
        })
    },
    async doAddCard(){
      let vald = true;
      let message = '';
      if(this.DesWrk.length < 1){message = this.lang.work_description_required;vald = false;}
      if(this.customer.mobile=='' && vald == true){message = this.lang.customer_info_required;vald = false;}
      //if(this.date1 == '' && vald == true && this.deltime == true){message = this.lang.delivery_date_required;vald = false;}
      if((this.car.plate_number == '' || this.car.model =='' || this.car.made_year == '') 
        && vald == true && this.carinfo == true){message = this.lang.car_info_required;vald = false;}
      if(!vald){
        this.$snotify.error(message, 'تنبيه', {
            timeout: 1000000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: SnotifyPosition.centerCenter,
            buttons: [
                {
                    text: this.lang.close, 
                    action: (toast) => {
                        this.$snotify.remove(toast.id); 
                    } 
                },
            ]
        });
        return false;
      }
      document.getElementById('mysubmit').disabled = true;
      let _cardid = 0;
      const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
      const auth = this.$cookies.get(this.$store.state.COOKIEPhase)
      post.append("auth",auth);
      post.append("type",'addCard');
      post.append('data[customer][full_name]',this.customer.full_name);
      post.append('data[customer][mobile]',this.customer.mobile);
      post.append('data[customer][mobile2]',this.customer.mobile2);
      post.append('data[customer][company_name]',this.customer.company_name);
      post.append('data[customer][vatid]',this.customer.vatid);
      post.append('data[customer][crt_number]',this.customer.crt_number);
      post.append('data[customer][address]',this.customer.address);
      post.append('data[customer][building_no]',this.customer.building_no);
      post.append('data[customer][street_name]',this.customer.street_name);
      post.append('data[customer][branch_number]',this.customer.branch_number);
      post.append('data[customer][district]',this.customer.district);
      post.append('data[customer][city]',this.customer.city);
      post.append('data[customer][zipcode]',this.customer.zipcode);
      post.append('data[customer][entity_name]',this.company.entity_name);
      post.append('data[customer][branch_name]',this.company.branch_name);
      post.append('data[customer][customer_type]',this.customer.customer_type);
      post.append('data[customer][cctype]',this.customer.customer_type);
      // // console.log("custoemr",this.customer);
      post.append('data[car][plate_number]',this.car.plate_number);
      post.append('data[car][color]',this.car.color);
      post.append('data[car][model]',this.car.model);
      post.append('data[car][workdes]',this.car.workdes);
      post.append('data[car][made_year]',this.car.made_year);
      post.append('data[car][vin]',this.car.vin);
      post.append('data[car][workers]',this.workerssel);
      
      if(this.$refs.mywork.clicked.length != 0){
        this.clicked = '';
        for(let j = 0;j<this.$refs.mywork.clicked.length;j++){
          this.clicked = this.clicked == '' ? this.$refs.mywork.clicked[j] : this.clicked + "," + this.$refs.mywork.clicked[j]
        }
      }
      post.append('data[car][clicked]',this.clicked);
      post.append('data[car][card_status]',this.card_status);
      post.append('data[payment][ftotal]',this.servicePrice);
      post.append('data[payment][paid]',this.payment.paid);
      post.append('data[in_date]',this.date);
      post.append('data[out_date]',this.date1);
      post.append('data[vattype]',this.vattype);
      post.append('data[paytype]',this.paytype);
      post.append('data[is_company]',this.isCompany);
      post.append('data[company_name]',this.customer.company_name);
      post.append('data[company_vatid]',this.customer.vatid);
      post.append('data[request_number]',this.company.request_number);
      post.append('data[po_number]',this.company.po_number);
      post.append('data[po_date]',this.company.po_date);
      post.append('data[order_number]',this.company.order_number);

      const Wok = this.DesWrk;
      for(let ix = 0; ix < Wok.length;ix++){
          //--- workd desc
          post.append("data[card_item][service]["+ix+"][description]",Wok[ix].work);
          post.append("data[card_item][service]["+ix+"][qty]",1);
          post.append("data[card_item][service]["+ix+"][price]",Wok[ix].price);
          post.append("data[card_item][service]["+ix+"][cost]",0);
          //--- employees
          post.append("data[card_item][labor]["+ix+"][description]",Wok[ix].name);
          post.append("data[card_item][labor]["+ix+"][empid]",Wok[ix].empl);
          post.append("data[card_item][labor]["+ix+"][qty]",1);
          post.append("data[card_item][labor]["+ix+"][price]",0);
          post.append("data[card_item][labor]["+ix+"][cost]",0);
      }

      post.append("data[receipt][doc_type]","job_card");
      post.append("data[receipt][voutcherid]",0);
      post.append("data[receipt][received_from]",this.customer.full_name);
      post.append("data[receipt][mobile]",this.customer.mobile);
      post.append("data[receipt][type]",1);
      post.append("data[receipt][pay_reson]",0);
      post.append("data[receipt][vattype]",this.vattype);
      post.append('data[receipt][paytype]',this.paytype);
      
      let vtype = 3;
      // if(this.vattype == 1) vtype = 2;
      // if(this.vattype == 2) vtype = 3;
      // if(this.vattype == 3) vtype = 1;
      const vc = this.$calcVat(this.payment.paid,3);
     
      post.append("data[receipt][total]",vc.tot);
      post.append("data[receipt][vat]",vc.vat);
      post.append("data[receipt][ftotal]",vc.ftot);
      post.append("data[receipt][paytype]",this.paytype);
      post.append("data[receipt][paycash]",this.paycash);
      post.append("data[receipt][payspan]",this.payspan);
      post.append("data[receipt][receipt_date]",this.date);
      for(let i = 0;i<this.scrachNoties.length;i++){
        post.append("data[scrachnotes]["+i+"]",this.scrachNoties[i]);
      }
      for(let i=0;i<this.attached.length;i++){
          post.append('data[files]['+i+']',this.attached[i]);
      }
      axios.post(
        this.$store.state.SAMCOTEC.r_path,
        post
      ).then(
        (response) => {
          // console.log(response.data);
          document.getElementById('mysubmit').disabled = false;
          _cardid = response.data.results.data.results[0].cardid;
          if(response.data.error.number == 200){
            document.getElementsByClassName('closeme')[0].click();
          }
        }
      ).then((response) => {
        this.$snotify.error(this.lang.added_sucess, 'تنبيه', {
            timeout: 1000000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: SnotifyPosition.centerCenter,
            buttons: [
                {
                    text: this.lang.close, 
                    action: (toast) => {
                        this.$snotify.remove(toast.id); 
                    } 
                },
            ]
        });
        
        if(this.$parent.ishome)
          this.$parent.getCurrentCards();
      }).then((response) => {
          setTimeout(() => {
            // this.$router.push({path: '/view-card/'+_cardid});
            document.getElementById('mysubmit').disabled = false;
          },500);
      })
    }
  }
}
</script>

<style>
h6{
  font-weight: bolder;
}
.v-input--selection-controls {
    margin-top: 2px !important;
    padding-top: 2px !important;
}
.tobbuttom{
  display:flex;
  flex-direction: row;
  justify-content:center;
  justify-items: center;
  align-items: center;
}
.borderRad_left{
  border-top-left-radius: 5px 5px !important;
  border-bottom-left-radius: 5px 5px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding:5px 10px !important;
  font-size: .8rem  !important;
  margin-inline-start: 5px !important;
}
.borderRad_right{
  border-top-left-radius: 0 0 !important;
  border-bottom-left-radius: 0 0 !important;
  border-top-right-radius: 5px 5px !important;
  border-bottom-right-radius: 5px 5px !important;
  padding:5px 10px !important;
  font-size: .8rem  !important;
  margin-inline-end: 5px  !important;
}
.borderRad_none{
  border-top-left-radius: 0 0 !important;
  border-bottom-left-radius: 0 0 !important;
  border-top-right-radius: 0 0 !important;
  border-bottom-right-radius: 0 0 !important;
  padding:5px 10px !important;
  font-size: .8rem  !important;
}
.hideme{
  display:none !important;
}
.dispFlex{
  display:flex;
  justify-content: center !important;
}
.weightColor{
  background:#fff !important;
  color:#000 !important;
}
.redColor{
  background:red !important;
  color:#fff !important;
}
</style>